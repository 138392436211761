import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
// import Table, {TableBody, TableCell, TableHead, TableRow} from '@material-ui/core/Table';
// import Paper from '@material-ui/core/Paper';
import {TextField, Typography, Button, Toolbar} from '@material-ui/core';
//import IconButton from '@material-ui/core/IconButton';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FormGroup from '@material-ui/core/FormGroup';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';
//import Dialog from '@material-ui/core/Dialog';
//import Slide from '@material-ui/core/Slide';
import MenuItem from './menu_item';
import AddIcon from '@material-ui/icons/Add';
//import DeleteIcon from '@material-ui/icons/Delete';

import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';

// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }


class WebMenuDetail extends Component {
  constructor(props) {
    super(props);
    var obj = {title:'',url:'',items:[]}
    this.state = {
      menu: {
        name:'',
        menuVN: [obj],
        menuEN: [obj],
        // menuKR: [obj]
      },
      error: {
        vn: false,
        en: false,
        // kr: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false,
      source: null,
      oldIndex: null,
      newIndex: null
    }
  }
  componentDidMount() {
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;
    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('menus',params.id, function(res) {
          if (res.status == 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                //console.log(item[property]);
                data[property] = item[property];
              }
            }
            data._id = item._id;
            $this.setState({menu: data},function(){
              //console.log($this.state.menu);
            });
          }
        });
      });
    }
  }
  addMoreItem(flag){
    var menu = this.state.menu,
        data = menu['menu'+flag.toUpperCase()]!==undefined?menu['menu'+flag.toUpperCase()]:[],
        l = data.length,
        obj = {title:'',url:'',items:[]};
        data.push(obj);
        menu['menu'+flag.toUpperCase()] = data;
        this.setState({menu:menu},function(){

        });

  }
  removeItem(flag,index){
    var menu = this.state.menu,
        data = menu['menu'+flag.toUpperCase()],
        l = data.length;
        if(l>1){
          data.splice(index,1);
          menu['menu'+flag.toUpperCase()] = data;
          this.setState({menu:menu},function(){

          });
        }
  }
  handleFieldChange(e) {
    var $this = this,
        menu = this.state.menu;
    menu[e.target.id] = e.target.value.trim();
    this.setState({menu:menu});
  }
  handleSaveMenuItem(flag,index,item){
    var $this = this,
        field = 'menu'+flag.toUpperCase(),
        menu = this.state.menu;
    menu[field][index] = item;
    //console.log(menu[field]);
    this.setState({menu:menu},function(){
      //console.log($this.state.menu);
    });
  }
  handleDeleteMenuItem(flag,index,item){
    var $this = this,
        field = 'menu'+flag.toUpperCase(),
        menu = this.state.menu;
    menu[field].splice(index,1);
    //console.log(menu[field]);
    this.setState({menu:menu},function(){
      //console.log($this.state.menu);
    });
  }
  handleValidate(e,flag) {
    var $this = this,
        menu = this.state.menu,
        obj = this.state.error;

    menu[e.target.id] = e.target.value.trim();
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() == '' || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;
    this.setState({menu: menu, error:obj}, () => {


    });
  }
  _save(){
    var $this = this;
    var data = this.state.menu;
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('menu',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/menu/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('menu',data, function(res) {
        if (res.status == 'success') {
          //$this.context.router.history.push('/menu/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          this._save();
          break;
        case 'en':
          this._save();
          break;
        // case 'kr':
        //   this._save();
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push('/menu/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleCopy(){
    var data = this.state.menu;
    data.menuEN = data.menuVN;
    //data.menuKR = data.menuVN;
    this.setState({
      menu: data
    })
  }
  handleDragStart(e,item,index,language){
    var event = e;
    //event.dataTransfer.setData("text/plain", event.target.innerHTML);
    event.dataTransfer.effectAllowed = "move";
    this.setState({
      source: event.target,
      oldIndex: index
    });

  }
  handleDragOver(e){
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }
  handleDragEnd(e){
    //console.log(e);
  }
  handleDrop(e,item,index,language){
    //drop
    e.preventDefault();
    e.stopPropagation();
    var $this = this,
        menu = this.state.menu,
        data = menu['menu'+language],
        old = data[$this.state.oldIndex];

    data.splice($this.state.oldIndex,1);
    data.splice(index,0,old);
    menu['menu'+language] = data;
    this.setState({
      menu:menu
    },function(){

    });
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const menus = this.state.menu;
    // menus.menuVN.map((x,i)=>{
    //   console.log(x);
    // })
    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa Menu</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <form className={classes.form + ' menu-item-list'} noValidate>
            <Button variant="contained" color="secondary" aria-label="copy" onClick={()=>this.handleCopy()}>Copy to [EN]</Button>
            <TextField id="name" label="Menu" className={classes.textField} value={this.state.menu.name} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <Button variant="contained" color="primary" aria-label="add" size="small" onClick={()=>this.addMoreItem('vn')}>
            Thêm <AddIcon />
            </Button>
            {
              menus.menuVN!==undefined && menus.menuVN.map((x,i)=>{
                return (
                  <div className={classNames('menu-item-wrapper',classes.drag_item)} key={i} draggable={true} onDragStart={(e)=>this.handleDragStart(e,x,i,'VN')} onDragOver={(e)=>this.handleDragOver(e)} onDragEnd={(e)=>this.handleDragEnd(e)} onDrop={(e)=>this.handleDrop(e,x,i,'VN')}><MenuItem item={x} items={x.items} flag='vn' onChangeValue={(menu)=>this.handleSaveMenuItem('vn',i,menu)} onDelete={(menu)=>this.handleDeleteMenuItem('vn',i,menu)}></MenuItem></div>
                  )
              })
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <Button variant="contained" color="primary" aria-label="add" size="small" onClick={()=>this.addMoreItem('en')}>
            Thêm <AddIcon />
            </Button>
            {
              menus.menuEN!==undefined&&menus.menuEN.map((x,i)=>{
                return <MenuItem className={classNames('menu-item-wrapper',classes.drag_item)} key={i} item={x} items={x.items} flag='en' onChangeValue={(menu)=>this.handleSaveMenuItem('en',i,menu)} onDelete={(menu)=>this.handleDeleteMenuItem('en',i,menu)}  draggable={true} onDragStart={(e)=>this.handleDragStart(e,x,i,'EN')} onDragOver={(e)=>this.handleDragOver(e)} onDragEnd={(e)=>this.handleDragEnd(e)} onDrop={(e)=>this.handleDrop(e,x,i,'EN')}></MenuItem>
              })
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
      </div>
    )
  }
}

WebMenuDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
WebMenuDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(WebMenuDetail);
