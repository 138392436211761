import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import Utils from '../api/api';
import {styles} from '../layout/theme';


class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url:'',
      title: '',
      items: [],
      source: null,
      oldIndex: null,
      newIndex: null
    }
  }
  componentDidMount() {
    var obj = this.props.item;
    this.setState({
      url: obj.url,
      title: obj.title,
      items: obj.items
    },function(){
      //console.log($this.state);
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    //console.log(nextProps);
    this.setState({
      url: nextProps.item.url,
      title: nextProps.item.title,
      items: nextProps.item.items
    },function(){
      //console.log($this.state);
    });
  }
  handleSaveMenuItem(index,menu){
    //console.log(menu);
    var obj = this.state;
    obj.items[index] = menu;
    this.setState(obj,function(){
      //console.log($this.state.menu);
    });
  }
  handleDeleteMenuItem(index,menu,parent){
    var arr = parent.props.items.splice(index,1),
        obj = parent.state;
    obj.items = arr;
    parent.setState(obj);
  }
  handleFieldChange(e) {
    var field = e.target.id,
        obj = this.state,
        $this = this;
    obj[field] = e.target.value;

    if(e.target.id==='title'){
      var url = Utils.getFriendlyURL(e.target.value);
      obj.url = url;
    }

    this.setState(obj,function(){
      //console.log($this.state);
      $this.props.onChangeValue(obj);
    });
  }
  addMoreItem(){
    var items = this.state.items,
        obj = {title:'',url:'',items:[]};
        items.push(obj);
        this.setState({items:items},function(){
          //console.log(this.state);
        });
  }
  removeItem(){
    var obj = this.state;
    if(typeof this.props.onDelete==='function'){
      this.props.onDelete(obj);
    }else{
      //console.log(this.props);
    }
  }
  handleDragStart(e,item,index){
    var event = e;
    //event.dataTransfer.setData("text/plain", event.target.innerHTML);
    event.dataTransfer.effectAllowed = "move";
    this.setState({
      source: event.target,
      oldIndex: index
    });

  }
  handleDragOver(e){
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }
  handleDragEnd(e){
    //console.log(e);
  }
  handleDrop(e,item,index){
    //drop
    e.preventDefault();
    e.stopPropagation();
    var $this = this,
        menu = this.state.items,
        data = this.state.items,
        old = data[$this.state.oldIndex];
    
    data.splice($this.state.oldIndex,1);
    data.splice(index,0,old);
    menu = data;
    this.setState({
      items:menu
    },function(){
      
    });
  }
  render() {
    const classes = this.props.classes;
    //const items = this.props.items;
    const flag = this.props.flag;
    var $this = this;
    let childnodes = null;
    // the Node component calls itself if there are children
    if(this.props.items) {
      childnodes = this.props.items.map(function(childnode,i) {
        return (
          <div key={i} className={classNames('menu-item-wrapper',classes.drag_item)} key={i} draggable={true} onDragStart={(e)=>$this.handleDragStart(e,childnode,i)} onDragOver={(e)=>$this.handleDragOver(e)} onDragEnd={(e)=>$this.handleDragEnd(e)} onDrop={(e)=>$this.handleDrop(e,childnode,i)}>
            <MenuItem item={childnode} items={childnode.items} classes={classes} flag={flag} onChangeValue={(menu)=>$this.handleSaveMenuItem(i,menu)} onDelete={(menu)=>$this.handleDeleteMenuItem(i,menu,$this)}></MenuItem>
          </div>
        );
      });
    }
    return (
      <div className="menu-item">
        <div className="menu-item-button">
          <Button variant="contained" color="primary" aria-label="add" size="small" onClick={()=>this.addMoreItem()}>
        Thêm <AddIcon /></Button>
        <IconButton color="secondary" aria-label="delete" size="small" onClick={()=>this.removeItem()}>
          <DeleteIcon />
        </IconButton>
        </div>
        <TextField id="title" label="Tiêu đề" className={classes.textField} value={this.state.title} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
          shrink: true
        }}/>
        <TextField id="url" label="Liên kết" className={classes.textField} value={this.state.url} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
          shrink: true
        }}/>
        { childnodes ?
          <div>{childnodes}</div>
        : null }
      </div>
    )
  }
}

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired
};
MenuItem.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(MenuItem);
